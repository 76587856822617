import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                toggleRadio: 'toggleRadio',
            },
        };
    }

    init() {

    }

    toggleRadio(e) {
        const el = e.currentTarget;
        const target = el.getAttribute('data-target');
        $(this.el).find('.toggleRadio').addClass('is-hidden');
        if(target) {
            if($(el).is(':checked')) {
                $(target).removeClass('is-hidden');
            }else {
                $(target).addClass('is-hidden');
            }
        }
    }

}