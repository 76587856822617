import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                nav: 'toggleNav',
                adherent: 'toggleAdherent',
                search: 'toggleSearch',
                login: 'toggleLogin',
            }
        }
    }

    init() {

    }

    toggleNav(e) {
        const burger = this.$('nav')[0];
        // const text = burger.querySelector('.-hover');
        const html = document.querySelector('html');
        html.classList.remove('has-navAdherentOpen');
        html.classList.remove('has-navSearchOpen');
        html.classList.remove('has-navLoginOpen');
        if(html.classList.contains('has-navOpen')) {
            html.classList.remove('has-navOpen');
            burger.classList.remove('is-active');
            // text.textContent = text.dataset.texthover;
        } else {
            html.classList.add('has-navOpen');
            burger.classList.add('is-active');
            // text.textContent = text.dataset.textclose;
        }
    }

    toggleAdherent(e) {
        const el = e.currentTarget;
        const html = document.querySelector('html');
        html.classList.remove('has-navOpen');
        html.classList.remove('has-navSearchOpen');
        html.classList.remove('has-navLoginOpen');
        if(html.classList.contains('has-navAdherentOpen')) {
            html.classList.remove('has-navAdherentOpen');
            el.classList.remove('is-active');
        } else {
            html.classList.add('has-navAdherentOpen');
            el.classList.add('is-active');
        }
    }

    toggleSearch(e) {
        const el = e.currentTarget;
        const html = document.querySelector('html');
        html.classList.remove('has-navOpen');
        html.classList.remove('has-navAdherentOpen');
        html.classList.remove('has-navLoginOpen');
        if(html.classList.contains('has-navSearchOpen')) {
            html.classList.remove('has-navSearchOpen');
            el.classList.remove('is-active');
        } else {
            html.classList.add('has-navSearchOpen');
            el.classList.add('is-active');
        }
    }

    toggleLogin(e) {
        const el = e.currentTarget;
        const html = document.querySelector('html');
        html.classList.remove('has-navOpen');
        html.classList.remove('has-navAdherentOpen');
        html.classList.remove('has-navSearchOpen');
        if(html.classList.contains('has-navLoginOpen')) {
            html.classList.remove('has-navLoginOpen');
            el.classList.remove('is-active');
        } else {
            html.classList.add('has-navLoginOpen');
            el.classList.add('is-active');
        }
    }
}
