import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.dragging = false,
        this.scrolling = false,
        this.resizing = false;
    }

    init() {
        let $this = this;
        //cache jQuery objects
        var imageComparisonContainers = $(this.el);
        //check if the .b-avant-apres_image-container is in the viewport
        //if yes, animate it
        this.checkPosition(imageComparisonContainers);

        //make the .b-avant-apres_handle element draggable and modify .b-avant-apres_resize-img width according to its position
        $this.drags(
            imageComparisonContainers.find('.b-avant-apres_handle'), 
            imageComparisonContainers.find('.b-avant-apres_resize-img'), 
            imageComparisonContainers, 
            imageComparisonContainers.find('.b-avant-apres_image-label[data-type="original"]'), 
            imageComparisonContainers.find('.b-avant-apres_image-label[data-type="modified"]'));

        //upadate images label visibility
        $(window).on('resize', function(){
            if( !$this.resizing) {
            $this.resizing =  true;
            ( !window.requestAnimationFrame )
                ? setTimeout(function(){$this.checkLabel(imageComparisonContainers);}, 100)
                : requestAnimationFrame(function(){$this.checkLabel(imageComparisonContainers);});
            }
        });
    }

    checkPosition(container) {
        container.addClass('is-visible');
  
        this.scrolling = false;
    }

    checkLabel(container) {
        let $this = this;
        container.each(function(){
          var actual = $(this);
          $this.updateLabel(actual.find('.b-avant-apres_image-label[data-type="modified"]'), actual.find('.b-avant-apres_resize-img'), 'left');
          $this.updateLabel(actual.find('.b-avant-apres_image-label[data-type="original"]'), actual.find('.b-avant-apres_resize-img'), 'right');
        });
  
        this.resizing = false;
    }

    drags(dragElement, resizeElement, container, labelContainer, labelResizeElement) {
        let $this = this;
        dragElement.on('mousedown vmousedown', function(e) {
          dragElement.addClass('draggable');
          resizeElement.addClass('resizable');
  
          var dragWidth = dragElement.outerWidth(),
            xPosition = dragElement.offset().left + dragWidth - e.pageX,
            containerOffset = container.offset().left,
            containerWidth = container.outerWidth(),
            minLeft = containerOffset + 10,
            maxLeft = containerOffset + containerWidth - dragWidth - 10;
  
          dragElement.parents().on('mousemove vmousemove', function(e) {
            if( !$this.dragging) {
              $this.dragging =  true;
              ( !window.requestAnimationFrame )
                  ? setTimeout(function(){$this.animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);}, 100)
                  : requestAnimationFrame(function(){$this.animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);});
            }
            // eslint-disable-next-line no-unused-vars
          }).on('mouseup vmouseup', function(e){
            dragElement.removeClass('draggable');
            resizeElement.removeClass('resizable');
          });
          e.preventDefault();
          // eslint-disable-next-line no-unused-vars
        }).on('mouseup vmouseup', function(e) {
          dragElement.removeClass('draggable');
          resizeElement.removeClass('resizable');
        });
    }

    animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement) {
        var leftValue = e.pageX + xPosition - dragWidth;
        //constrain the draggable element to move inside his container
        if(leftValue < minLeft ) {
          leftValue = minLeft;
        } else if ( leftValue > maxLeft) {
          leftValue = maxLeft;
        }
  
        var widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';
  
        $('.draggable').css('left', widthValue).on('mouseup vmouseup', function() {
          $(this).removeClass('draggable');
          resizeElement.removeClass('resizable');
        });
  
        $('.resizable').css('width', widthValue);
  
        this.updateLabel(labelResizeElement, resizeElement, 'left');
        this.updateLabel(labelContainer, resizeElement, 'right');
        this.dragging =  false;
    }

    updateLabel(label, resizeElement, position) {
        if(position == 'left') {
          ( label.offset().left + label.outerWidth() < resizeElement.offset().left + resizeElement.outerWidth() ) ? label.removeClass('is-hidden') : label.addClass('is-hidden') ;
        } else {
          ( label.offset().left > resizeElement.offset().left + resizeElement.outerWidth() ) ? label.removeClass('is-hidden') : label.addClass('is-hidden') ;
        }
    }

}
