import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              toggle: 'toggle',
            }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if(this.getData('init')) {
            $(this.$('toggle')[0]).trigger('click');
        }
    }

    toggle(e) {
        let el = e.currentTarget;
        let id = $(el).attr('data-id');
        let key = $(el).attr('data-key');

        $('.b-relation_temoignages_btn').removeClass('is-active');

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'toggleTemoignage', 'post_id': id},
            success: function (response) {
                $(el).addClass('is-active');
                $('.b-relation_temoignages_wrap').html(response.content);
                $('.b-relation_temoignages_count').html(key);
            },
        });
    }
}
