import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                close: 'close',
            }
        }
    }

    init() {

    }

    close() {
        const html = document.querySelector('html');
        html.classList.remove('has-navOpen');
        html.classList.remove('has-navSearchOpen');
        html.classList.remove('has-navAdherentOpen');
        html.classList.remove('has-navLoginOpen');
    }
}
