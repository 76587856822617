import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              select: 'select',
            }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if(this.$('select')[0]) {
            $(this.$('select')[0]).trigger('click');
        }
    }

    select(e) {
        const current_el = e.currentTarget;
        const folderid = current_el.getAttribute('data-folderid');
        const target = current_el.getAttribute('data-target');
        const t = this;

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'refresh_dossiers', 'folderid': folderid},
            success: function (response) {
                
                if ($(target).length) {
                    $(target).html(response.content);
                }
                setTimeout(() => {
                    t.call('update', false, 'Scroll'); // update Locomotive Scroll
                    // t.call('scrollTo', '.b-relation_listing', 'Scroll');
                }, 500);
            },
        });
    }

    refresh() {
        const t = this;
        const folder_id = $('.main-folder-btn.is-active').attr('data-folderid');
        const target = $('.main-folder-btn.is-active').attr('data-target');

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'refresh_dossiers', 'folderid': folder_id},
            success: function (response) {
                
                if ($(target).length) {
                    $(target).html(response.content);
                }
                setTimeout(() => {
                    t.call('update', false, 'Scroll'); // update Locomotive Scroll
                    // t.call('scrollTo', '.b-relation_listing', 'Scroll');
                }, 500);
            },
        });
    }
}
