import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              toggle: 'toggle',
              pagination: 'pagination',
            }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if(this.getData('init')) {
            $(this.$('toggle')[0]).trigger('click');
        }
    }

    toggle(e) {
        let el = e.currentTarget;
        let id = $(el).attr('data-id');

        $('.b-relation_formations_btn_wrap').removeClass('is-active');

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'toggleFormation', 'id': id},
            success: function (response) {
                console.log(response);
                $(el).parent('.b-relation_formations_btn_wrap').addClass('is-active');
                $('.b-relation_formations_wrap').html(response.content);
            },
        });
    }

    pagination(e) {
        let el = e.currentTarget;
        let id = $(el).attr('data-id');
        let paged = $(el).attr('data-paged');

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'toggleFormation', 'id': id, 'paged': paged},
            success: function (response) {
                $('.b-relation_formations_wrap').html(response.content);
            },
        });
    }
}
