import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              toggle: 'toggle',
              select: 'select',
              close: 'close',
            }
          }
    }

    init() {

    }

    toggle() {
        let elements = document.querySelectorAll('.c-dropdown');
        let el = this.el;
        elements.forEach(element => {
          if(element !== el) {
            element.classList.remove('is-open');
          }
        });

        el.classList.toggle('is-open');
    }

    close() {
      this.el.classList.remove('is-open');
    }

    select(e) {
        const target = e.currentTarget;

        const content = $(target).find('.c-button_label').html();

        $(target).parents('.c-dropdown').find('.c-dropdown_selected').html(content);

        this.toggle();
    }

    reset() {
        if(this.$('select')[0]) {
            $(this.$('select')[0]).trigger('click');
            this.toggle();
        }
    }
}
