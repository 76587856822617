import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                submitAjax: 'submitAjax',
                submit: 'submit',
                append: 'append',
                remove: 'remove',
                updateName: 'updateName',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      if(jQuery('.switch-tmce').length) {
        setTimeout(() => {
          jQuery('.switch-tmce').trigger('click');
        }, 1000);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(!urlParams.get('init') == 1) {
          window.location.href = window.location+'?init=1';
        }
      }
    }

    submitAjax(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');
      const $this = this;

      form.find('.c-form_msg').html('');

      this.checkForm(form);

      if(form.valid()) {
        var data = new FormData(form[0]);

        if(form.find('#messagerie_message_ifr').length) { // Recup le html de l'iframe wp_editor pour sauvegarder le derniere version
          var wp_editor_iframe = form.find('#messagerie_message_ifr');
          var post_contents = $('#tinymce', wp_editor_iframe.contents())[0].innerHTML;
          data.append('message', post_contents);
        }
        
        $.ajax({
          url: this.ajaxurl,
          type: 'POST',
          data: data,
          processData: false,
          contentType: false,
          beforeSend: function() {
            form.find('.c-form_action .c-button').hide();
          },
          success: function( response ){
            
            if(response.redirectto) {
              window.location.href = response.redirectto;
            }
            form.find('.c-form_msg').addClass('u-margin-top-small');
            if (response.msg) {
              form.find('.c-form_msg').html(response.msg);
              form.find('.c-form_msg').show();
            }
            if(response.status == 'success') {
              form.find('.c-form_msg').removeClass('error');
              form.find('.c-form_msg').addClass('success');
              if(response.reset) {
                form[0].reset();
              }
  
              if(response.modal_content) {
                $('.c-modal').find('.c-modal_wrap').html(response.modal_content);
                $this.call('softOpen', false, 'Modal');
              }
              if(response.modal) {
                $this.call('close', false, 'Modal');
              }

              if(response.module) {
                $this.call(response.module.function, false, response.module.module);
                $this.call('reset', false, 'Dropdown');
              }
            }else {
              form.find('.c-form_msg').removeClass('success');
              form.find('.c-form_msg').addClass('error');
            }
          },
          complete: function() {
            form.find('.c-form_action .c-button').show();
          },
        });
      }else {
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    submit(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');

      this.checkForm(form);

      if(form.valid()) {
        form.submit();
      }else {
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    checkForm(form) {
      var $ = jQuery;
      var $msg_error = 'Ce champ est obligatoire';
      var $msg_error_email = 'Saisir une adresse email valide (ex: email@exemple.com)';
      var $msg_error_extension = 'Types de fichiers autorisés .jpg, .jpeg, .png, .pdf, .docx, .xlsx, .doc, .xls, .pptx, .odt, .ods';
      var $msg_error_filesize = 'Fichier trop volumineux, la taille maximale autorisée est de 10Mo';

      $.validator.addMethod('filesize', function (value, element, param) {
        return this.optional(element) || (element.files[0].size <= param)
      }, $msg_error_filesize);

      $(form).validate({
        ignore: [],
        lang: 'fr',
        errorElement : 'div',
        errorPlacement: function(error, element) {
          var placement = $(element).data('error');
          if (placement) {
            $(placement).append(error);
          } else {
            error.insertAfter(element);
          }
        },
      });
      const required = this.$('required');
      const rules = [];
      required.forEach(function(element) {
        if(!$(element).parents('.is-hidden').length) {
          $(element).rules('add', { required: true, messages : { required : $msg_error } });
          if ($(element).attr('type') == 'email') {
            $(element).rules('add', { email: true, messages : { email : $msg_error_email } });
          }
        }
      });

      const files = this.$('file');
        files.forEach(function(element) {
          if($(element).attr('type') == 'file') {
            // 5Mo : 5242880
            // 1Mo : 1048576
            // 10Mo : 10485760
            $(element).rules('add', { extension: 'jpeg|jpg|png|pdf|docx|xlsx|doc|xls|pptx|odt|ods', filesize : 10485760, messages : { extension : $msg_error_extension } });
          }
        });
    }

    append(e) {
      const target = e.currentTarget;
      let container = $(target).data('append');
      let template = $(target).data('template');

      var form = jQuery(target).parents('form');
      let $this = this;

      $.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: {action: 'form_append', template: template},
        success: function( response ){
          $(container).append(response.content);
          $this.call('update', false, 'Scroll'); // update Locomotive Scroll
        },
      });
    }

    remove(e) {
      const target = e.currentTarget;
      let targetContainerName = $(target).data('target');
      let targetContainer = $('[data-remove="' + targetContainerName + '"]');
      let $this = this;
      targetContainer.remove();
      // $this.call('update', false, 'Scroll'); // update Locomotive Scroll

      if($(target).data('postid')) {
        var postid = $(target).data('postid');
        $.ajax({
          url: this.ajaxurl,
          type: 'POST',
          data: {action: 'form_delete_post', postid: postid},
          success: function( response ){
            
          },
        });
      }
    }

    updateName(e) {
      const target = e.currentTarget;
      const accordion = $(target).parents('.c-accordion_item');
      let title = accordion.find('#reseau_titre').val();
      if(title) {
        accordion.find('.c-accordion_title').html(title);
      }
    }
}
