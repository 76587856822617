import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              toggle: 'toggle',
            }
          }
    }

    init() {

    }

    toggle(e) {
        const target = e.currentTarget;
        const buttons = this.$('toggle');
        const items = this.$('item');

        buttons.forEach(element => {
            element.classList.add('-black');
            element.classList.remove('is-active');
        });

        items.forEach(element => {
          element.classList.add('is-hidden');
        });

        const dataid = target.getAttribute('data-id');
        target.classList.remove('-black');
        target.classList.add('is-active');
        $('[data-id="'+dataid+'"]').removeClass('is-hidden');
    }
}
