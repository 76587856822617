import { module } from 'modujs';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      const data = JSON.parse(this.getData('data'));
      const type = this.getData('type');
      let legend = {
        display: true,
        position: 'right',
        labels: {
          fontSize: 22,
          padding: 20,
        }
      };
      
      if(type == 'bar' || type == "line") {
        legend = {
          display: false,
        }
      }

      const options = {
        plugins: {
          legend: legend,
        }
      };

      const _labels = [];
      const _data = [];
      const _backgroundColor = [];

      data.forEach(element => { 
        _labels.push(element['titre']);
        _data.push(element['valeur']);
        _backgroundColor.push(element['couleur']);
      });

      const ctx = this.el.getContext('2d');

      const chart = new Chart(ctx, {
        type: type,
        data: {
          labels: _labels,
          datasets: [{
            //label: '# of Votes',
            data: _data,
            backgroundColor: _backgroundColor,
            borderWidth: 1
          }]
        },
        options: options,
      });
    }
}
